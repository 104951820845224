/**
 * Page Scrolling
 * Collection of functions to define behaviour depending
 * on how far the user has scrolled the page.
 * (1) watchPageSections -> observes the article elements on the
 * page and assigns a marker class to those that are in the viewport.
 * (2) watchThreshold -> sets a CSS marker class when the user has
 * scrolled the page at least a specified amount.
 */
const defaults = {
  pageSections: {
    selectorSections: 'article',
    threshold: [0.2, 0.35]
  },
  scrollThreshold: {
    scrollTresholdHeaderOut: 40,
    scrollTresholdHeaderIn: -20,
    cssClassHeaderOut: 'has-header-scrolled-out'
  }
};

const config = {pageSections: {}, scrollThreshold: {}};



/**
 * watchPageSections
 * Observes the article elements on the page and assigns a marker
 * class to those that are in the viewport.
 * To avoid listing to the scroll event which is never a good idea
 * we use an IntersectionObserver instance though it is not supported
 * by all browsers.
 *
 * @param {Object} options To overwrite defaults.pageSections
 */
module.exports.watchPageSections = function watchPageSections(options = {}) {
  function onChange(changes, observer) {
    changes.forEach(change => {
      if (change.intersectionRatio > 0) {
        observer.unobserve(change.target);
        change.target.classList.add('viewed');
      }
    });
  }

  try {
    Object.assign(config.pageSections, defaults.pageSections, options);
    const _config = config.pageSections;
    const elmsArticle = document.querySelectorAll(_config.selectorSections);

    if (!('IntersectionObserver' in window)) {
      const elmsArticle = document.querySelectorAll(_config.selectorSections);
      elmsArticle.forEach(a => a.classList.add('viewed'));
      return;
    }

    const observer = new IntersectionObserver(onChange, {
      root: null,
      rootMargin: '0px',
      threshold: _config.threshold
    });

    // init
    document.documentElement.classList.add('sections-slidein');
    window.setTimeout(() => {
      window.scrollTo(0, 0);
      elmsArticle.forEach(a => observer.observe(a));
    }, 100);
  }
  catch (err) {
    document.documentElement.classList.remove('sections-slidein');
    // …
  }
};



/**
 * watchScrolledTreshold
 * Assigns a CSS marker class to the BODY element when the
 * user has scrolled the page down more than a small threshold.
 * This marker class is removed again when the user scrolls up
 * at least the given threshold distance.
 *
 * Use the marker class to create “smart sticky” elements that
 * are fixed initially but get moved out of the viewport
 * when the user scrolls down and that get pushed into view again
 * when the user scrolls up.
 *
 * @param {Object} options To overwrite defaults.scrollThreshold
 */

module.exports.watchScrolledTreshold = function watchScrolledTreshold(options = {}) {
  let _config;
  let lastPosY = window.pageYOffset;
  let scrolledDistance = 0;
  let scrollTresholdHeaderOut = 0;
  let scrollTresholdHeaderIn = 0;
  let cssClassHeaderOut = '';

  function updateHeaderStickyElms() {
    scrolledDistance += window.pageYOffset - lastPosY;
    lastPosY = window.pageYOffset;

    if (scrolledDistance > scrollTresholdHeaderOut) {
      document.documentElement.classList.add(cssClassHeaderOut);
      scrolledDistance = 0;
    }
    if (scrolledDistance < scrollTresholdHeaderIn) {
      document.documentElement.classList.remove(cssClassHeaderOut);
      scrolledDistance = 0;
    }
  }

  try {
    Object.assign(config.scrollThreshold, defaults.scrollThreshold, options);
    _config = config.scrollThreshold;
    scrollTresholdHeaderOut = _config.scrollTresholdHeaderOut;
    scrollTresholdHeaderIn = _config.scrollTresholdHeaderIn;
    cssClassHeaderOut = _config.cssClassHeaderOut;

    // init
    window.removeEventListener('scroll', updateHeaderStickyElms);
    window.addEventListener('scroll', updateHeaderStickyElms);
  }
  catch (err) {
    // …
  }
};
