(function () {
  require('intersection-observer');

  const pageScrolling = require('./page-scrolling');
  const articleModals = require('./article-modals');
  const pageNav = require('./page-nav');
  const sliders = require('./sliders');


  function init() {
    pageNav.init();
    articleModals.init();
    sliders.init();
    pageScrolling.watchScrolledTreshold();
    pageScrolling.watchPageSections();
    watchForHover();
  }

  // An ugly old-school solution to prevent touch devices from showing hover states.
  // The first approach was using feature queries in CSS (@media (hover: hover)) but
  // this doesn’t work reliably (Android e.g.) as of 2013-03-14.
  function watchForHover() {
    var hasHoverClass = false;
    var container = document.documentElement;
    var lastTouchTime = 0;

    function enableHover() {
      // filter emulated events coming from touch events
      if (new Date() - lastTouchTime < 500) {
        return;
      }
      if (hasHoverClass) {
        return;
      }
      container.className += ' has-hover';
      hasHoverClass = true;
    }

    function disableHover() {
      if (!hasHoverClass) {
        return;
      }
      container.className = container.className.replace(/(?:^|\s+)has-hover(?:$|\s+)/, '');
      hasHoverClass = false;
    }

    function updateLastTouchTime() {
      lastTouchTime = new Date();
    }

    document.addEventListener('touchstart', updateLastTouchTime, true);
    document.addEventListener('touchstart', disableHover, true);
    document.addEventListener('mousemove', enableHover, true);
    enableHover();
  }



  (function () {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', init);
    }
    else {
      init();
    }
    // for browsers that do not support DOMContentLoaded:
    // window.onload = init; // TODO: do we want it? if so prevent double call of init()
  }());

}());
