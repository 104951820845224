/**
 * Page Navigation
 * Toggles a navigation popup (by setting CSS marker classes)
 * and scrolls the respective article or section into view
 * when the user clicks a navigation link.
 */
const defaults = {
  cssClassNavVisible: 'on',
  delayShowTogglerOnLoad: 500,
  navId: 'nav-main'
};

const config = {};

let elmToggler;


function handleNavLinkClick(evt) {
  const elmClicked = evt.target;
  const targetId = elmClicked.getAttribute('href');
  let elmTarget;

  evt.stopPropagation();
  if (!targetId || !/^#/.test(targetId)) {
    return;
  }
  evt.preventDefault();
  elmTarget = document.getElementById(targetId.substr(1));
  if (elmTarget) {
    hideNav();
    elmTarget.scrollIntoView({behavior: 'smooth', block: 'start'});
  }
}

function handleKeyUp(evt) {
  if (evt.keyCode === 27) {
    hideNav();
  }
}

function handleNavTogglerClick(evt) {
  evt.preventDefault();
  evt.stopPropagation();
  toggleNav();
}

function hideNav() {
  const elmNav = document.getElementById(config.navId);

  elmNav.classList.remove(config.cssClassNavVisible);
  elmToggler.classList.remove(config.cssClassNavVisible);
}

function toggleNav() {
  const elmNav = document.getElementById(config.navId);
  let shouldShowNav;

  elmNav.classList.toggle(config.cssClassNavVisible);
  shouldShowNav = elmNav.classList.contains(config.cssClassNavVisible);
  elmToggler.classList.toggle(config.cssClassNavVisible, shouldShowNav);
  if (shouldShowNav) {
    document.documentElement.addEventListener('keyup', handleKeyUp);
  }
  else {
    document.documentElement.removeEventListener('keyup', handleKeyUp);
  }
}

module.exports.init = function init(options = {}) {
  try {
    Object.assign(config, defaults, options);
    const elmNav = document.getElementById(config.navId);

    elmToggler = document.querySelector(`[href="#${config.navId}"]`);
    if (!elmNav || !elmToggler) {
      return;
    }
    elmToggler.removeEventListener('click', handleNavTogglerClick);
    elmToggler.addEventListener('click', handleNavTogglerClick);
    elmNav.removeEventListener('click', handleNavLinkClick);
    elmNav.addEventListener('click', handleNavLinkClick);
    document.documentElement.addEventListener('click', hideNav);
    window.setTimeout(() => elmToggler.classList.add('ready'), config.delayShowTogglerOnLoad);
  }
  catch (err) {
    // …
  }
};
