/**
 * Article Modals
 * Provides the logic to show an articles sections as modal
 * popups. The actual visual behaviour is done with CSS only.
 * The following JS logic only creates a TOC (links referring
 * to the sections) and handles clicks on the TOC links and
 * on the close button. It only toggles CSS classes and does
 * no hiding/shwoing or animating.
 */
const defaults = {
  cssClassActiveSection: 'on',
  cssClassHasActiveSection: 'has-modal',
  cssClassCloser: 'section-modal-closer',
  cssClassNav: 'section-modal-nav',
  cssClassToc: 'section-modal-toc',
  selectorWrapper: 'article.section-modals',
  selectorSection: 'section',
  shouldBuildToc: true
};

const config = {};

let lastPosY = 0;

function addCloser(elmArticle) {
  const elmsSection = [...elmArticle.querySelectorAll(`${config.selectorSection}`)];
  const elmCloserDummy = document.createElement('span');
  let elmCloser;

  elmCloserDummy.innerHTML = '<i></i>';
  elmCloserDummy.classList.add(config.cssClassCloser);
  elmsSection.forEach(s => {
    elmCloser = elmCloserDummy.cloneNode(true);
    elmCloser.addEventListener('click', closeSectionModal);
    s.appendChild(elmCloser);
  });
}

function addNavigation(elmArticle) {
  const elmNav = document.createElement('div');

  elmNav.classList.add(config.cssClassNav);
  elmNav.innerHTML = '<span class="prev"><i class="icon"></i><i class="label"></i></span>';
  elmNav.innerHTML += '<span class="next"><i class="icon"></i><i class="label"></i></span>';
  elmArticle.appendChild(elmNav);
  elmNav.removeEventListener('click', handleClickNav);
  elmNav.addEventListener('click', handleClickNav);
}

function buildToc(elmArticle) {
  if (!config.shouldBuildToc) {
    return;
  }
  const elmsHeadline = elmArticle.querySelectorAll(`${config.selectorSection} > h2`);
  const markupToc = [...elmsHeadline]
    .map(h => `<span>${h.innerHTML}</span>`)
    .join('');
  const elmToc = document.createElement('div');

  elmArticle.insertBefore(elmToc, elmArticle.querySelector(`${config.selectorSection}`));
  elmToc.classList.add(config.cssClassToc);
  elmToc.innerHTML = markupToc;
  elmToc.removeEventListener('click', handleClickToc);
  elmToc.addEventListener('click', handleClickToc);
}

function closeSectionModal() {
  showSectionModal(this.parentNode.parentNode);
}

function disablePageScrolling() {
  lastPosY = window.pageYOffset;
  window.addEventListener('scroll', keepPageScrollPosition);
}

function enablePageScrolling() {
  window.removeEventListener('scroll', keepPageScrollPosition);
}

function handleClickNav(evt) {
  let elmClicked = evt.target;

  if (elmClicked.matches('.icon, .label')) {
    elmClicked = elmClicked.parentNode;
  }
  showSectionModal(this.parentNode, parseInt(elmClicked.getAttribute('data-index')));
}

function handleClickToc(evt) {
  const elmClicked = evt.target;
  let sectionIndex;

  if (!elmClicked.matches('span')) {
    return;
  }
  this.childNodes.forEach((elm, idx) => {
    if (elm === elmClicked) {
      sectionIndex = idx;
      elm.classList.add(config.cssClassActiveSection);
    }
    else {
      elm.classList.remove(config.cssClassActiveSection);
    }
  });
  showSectionModal(this.parentNode, sectionIndex);
  disablePageScrolling();
}

function keepPageScrollPosition() {
  window.scrollTo(0, lastPosY);
}

function showSectionModal(elmArticle, sectionIndex) {
  const elmsSection = [...elmArticle.querySelectorAll(`${config.selectorSection}`)];
  let hasModal = false;

  elmsSection.forEach((s, idx) => {
    hasModal = idx === sectionIndex || hasModal;
    s.classList.toggle(config.cssClassActiveSection, idx === sectionIndex);
  });
  elmArticle.classList.toggle(config.cssClassHasActiveSection, hasModal);
  document.documentElement.classList.toggle(config.cssClassHasActiveSection, hasModal);
  if (hasModal) {
    updateSectionNav(elmArticle, sectionIndex);
  }
  else {
    enablePageScrolling();
  }
}

function updateSectionNav(elmArticle, sectionIndex) {
  const headlines = [...elmArticle.querySelectorAll(`${config.selectorSection} > h2`)].map(h => h.innerHTML);
  const nextIndex = (sectionIndex + 1) % headlines.length;
  const prevIndex = sectionIndex ? sectionIndex - 1 : headlines.length - 1;
  const elmPrevLink = elmArticle.querySelector(`.${config.cssClassNav} > .prev`);
  const elmNextLink = elmArticle.querySelector(`.${config.cssClassNav} > .next`);

  elmPrevLink.setAttribute('data-index', prevIndex);
  elmPrevLink.querySelector('.label').innerHTML = headlines[prevIndex];
  elmNextLink.setAttribute('data-index', nextIndex);
  elmNextLink.querySelector('.label').innerHTML = headlines[nextIndex];
}

module.exports.init = function init(options = {}) {
  try {
    Object.assign(config, defaults, options);
    const elmsArticle = document.querySelectorAll(config.selectorWrapper);

    elmsArticle.forEach(a => {
      addCloser(a);
      addNavigation(a);
      buildToc(a);
    });
  }
  catch (err) {
    // …
  }
};
