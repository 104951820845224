/**
 * Sliders
 * Adds the needed additional markup to our slider elements
 * and then applies Swiper to them.
 */
const Swiper = require('swiper');

const defaultsSwiper = {
  autoHeight: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  loop: true,
  navigation: {
    nextEl: '.slider-nav .next',
    prevEl: '.slider-nav .prev'
  },
  speed: 400
};

const defaultsSwiperMulti = Object.assign({}, defaultsSwiper, {
  autoHeight: true,
  effect: 'slide',
  slidesPerView: 'auto',
  breakpoints: {
    // when window width is <= 768px
    768: {
      spaceBetween: 5
    },
    // when window width is <= 1024px
    1024: {
      spaceBetween: 14
    }
  }
});

const defaults = {
  selectorSliderWrapper: '.slider',
  swiper: defaultsSwiper,
  swiperMulti: defaultsSwiperMulti
};

const config = {};


function adjustMarkup(elmOuterWrapper) {
  const elmOuterWidgetWrapper = document.createElement('div');
  const elmInnerWidgetWrapper = document.createElement('div');
  const elmSlideWrapper = document.createElement('div');

  if (elmOuterWrapper.getAttribute('initialized')) {
    return;
  }
  elmOuterWidgetWrapper.classList.add('swiper-container');
  elmInnerWidgetWrapper.classList.add('swiper-wrapper');
  elmSlideWrapper.classList.add('swiper-slide');
  [...elmOuterWrapper.children].forEach(slide => {
    wrapSingleElm(slide, elmSlideWrapper.cloneNode(false));
  });
  wrapManyElms(elmOuterWrapper.querySelectorAll('.swiper-slide'), elmInnerWidgetWrapper);
  wrapSingleElm(elmInnerWidgetWrapper, elmOuterWidgetWrapper);
  elmOuterWrapper.innerHTML += `<div class="slider-nav">
    <div class="prev" aria-label="Zurück"><i class="icon"></i></div>
    <div class="next" aria-label="Weiter"><i class="icon"></i></div>
  </div>`;
}


function makeSlideable(elmOuterWrapper) {
  const elmOuterWidgetWrapper = elmOuterWrapper.querySelector('.swiper-container');
  const isMultiSlideMode = elmOuterWrapper.classList.contains('slider-multi');
  const swiperConfig = isMultiSlideMode ? config.swiperMulti : config.swiper;
  new Swiper(elmOuterWidgetWrapper, swiperConfig);
}


function wrapSingleElm(elmToWrap, elmWrapper) {
  wrapManyElms([elmToWrap], elmWrapper);
}


function wrapManyElms(elmsToWrap, elmWrapper) {
  const elmParent = elmsToWrap[0].parentNode;
  const elmSibling = elmsToWrap[0].nextSibling;

  elmsToWrap.forEach(e => elmWrapper.appendChild(e));
  if (elmSibling) {
    elmParent.insertBefore(elmWrapper, elmSibling);
  }
  else {
    elmParent.appendChild(elmWrapper);
  }
}


module.exports.init = function init(options = {}) {
  try {
    Object.assign(config, defaults, options);

    if (typeof Swiper !== 'function') {
      return;
    }
    document.querySelectorAll(config.selectorSliderWrapper).forEach(s => {
      adjustMarkup(s);
      makeSlideable(s);
      s.getAttribute('initialized');
    });
  }
  catch (err) {
    // …
  }
};
